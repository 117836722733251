module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":400,"backgroundColor":"transparent","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"de","siteUrl":"https://u1dent.app/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/cookie/","languages":["de"]},{"matchPath":"/privacy/","languages":["de"]},{"matchPath":"/agb/","languages":["de"]},{"matchPath":"/imprint/","languages":["de"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"u1Dent.app","short_name":"u1Dent","description":"u1Dent.app® ist der digitale Lernbegleiter für ZFA-Wissen und kann zur Ausbildung, Weiterbildung oder Wiedereingliederung genutzt werden.","lang":"de","display":"standalone","icon":"src/images/icon.png","start_url":"/","localize":[{"start_url":"/en/","lang":"en","name":"u1Dent.app","short_name":"u1Dent","description":"u1Dent.app® is the digital learning companion for ZFA knowledge and can be used for training, further education or reintegration."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4dc109fc65e7ecdffcbaffcf681943d7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
