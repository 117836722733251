exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer.js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-partnerportal-js": () => import("./../../../src/pages/partnerportal.js" /* webpackChunkName: "component---src-pages-partnerportal-js" */),
  "component---src-pages-praxen-js": () => import("./../../../src/pages/praxen.js" /* webpackChunkName: "component---src-pages-praxen-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

